/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        if ($('body').hasClass('has-intro')) {
          $(window).scroll(checkFixedHeader);
        }

        handleResize();
        $(window).resize(handleResize);

        if (!$('body').hasClass('home')) {
          $(window).scroll(layoutFixedHeader);
        }

        $('.mobile-nav-toggle').click(toggleMobileNav);

        $('.footer-newsletter-form').submit(function(e){
          e.preventDefault();
          handleNewsletterSignup($(this));
        });

        $('#safety-message .dismiss').click(function(){
          dismissSafetyMessage();
        }); 

        $('.signup-form-toggle').click(function(e){
          e.preventDefault();
          var targetId = '#signup-form-' + $(this).data("target");
          if ($(targetId).is(':visible')) {
            $(targetId).slideUp();
          } else {
            $(targetId).slideDown();
          }
        }); 

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        if (!Cookies.get('dismiss-safety')) {
          setTimeout(showSafetyMessage, 1000);
        }

        // Fire resize on image load in case they weren't loaded on the first layout
        $('.member-grid .member img').load(function(){
          handleResize();
        });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        $(document).ready(function(){

          // $('#intro .fader').slick({
          //   arrows: false,
          //   speed: 800,
          //   autoplay: true,
          //   autoplaySpeed: 6000,
          //   fade: true,
          //   cssEase: 'linear'
          // });

          $('.featured-content .slider').slick({
            speed: 600,
            autoplay: true,
            autoplaySpeed: 8000,
            appendArrows: '.featured-content .slider-nav',
            prevArrow: '<a href="#" class="prev">&lt;</a>',
            nextArrow: '<a href="#" class="next">&gt;</a>'
          });

        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  function checkFixedHeader() {
    var introOffset = $('#intro').offset().top + $('#intro').height();
    if ($(window).scrollTop() >= (introOffset)) {
      $('body').addClass('fixed-header');
    } else {
      $('body').removeClass('fixed-header');
    }
  }

  function showSafetyMessage() {
    $('body').addClass('show-safety-message');
    $("#safety-message").slideDown(400);
  }

  function dismissSafetyMessage() {
    $("#safety-message").slideUp(400, function(){
      $('body').removeClass('show-safety-message');
      $('#site-header').css('position', 'fixed');
      Cookies.set('dismiss-safety', 'true', { expires: 7 });
    });
  }

  function handleResize() {
    layoutFixedHeader();
    layoutBannerTitle();
    layoutStoriesGrid();
    layoutMemberGrid();
    layoutPostVideo();
  }

  function layoutFixedHeader() {
    if ($('body').hasClass('show-safety-message') && !$('body').hasClass('home')) {
      console.log('weeee');
      var msgHeight = $('#safety-message').height();
      if ($(document).scrollTop() >= msgHeight) {
        $('#site-header').css('position', 'fixed');
      } else {
        $('#site-header').css('position', 'absolute');
      }
    }
  }

  function layoutBannerTitle() {
    $('.page-banner h1').each(function(){
      $(this).css('margin-top', $(this).height() * -0.5 );
    });
  }

  function layoutMemberGrid() {
    // Fixed height for member grid items based on largest item
    if ($('.member-grid').length) {
      var maxH = 0;
      $('.member-grid .member').height('auto');
      if ($(window).width() > 768) { // screen-sm and up
        $('.member-grid').find('.member').each(function(){
          // set height for images in case they haven't loaded
          var img = $(this).find('img');
          img.height(img.width()); // always square images
          if ($(this).height() > maxH) {
            maxH = $(this).height();
          }
        });
        $('.member-grid .member').height(maxH);
      } else {
        $('.member-grid .member img').height('auto');
      }
    }
  }

  function layoutStoriesGrid() {
    if ($('.stories-grid').length) {
      var newHeight = $('.story-block').first().width();
      $('.story-block').each(function(){
        $(this).height(newHeight);
      });
    }    
  }

  function layoutPostVideo() {
    $('.post-video iframe').each(function(){
      $(this).height($(this).width() * (9 / 16)); // 16:9 Aspect Ratio
    });
  }

  function toggleMobileNav() {
    if ($('body').hasClass('display-mobile-nav')) {
      $('body').removeClass('display-mobile-nav')
    } else {
      $('body').addClass('display-mobile-nav')
    }
  }

  function handleNewsletterSignup(form) {
    var email = form.find('.email').val(),
        output = form.find('.msg').hide();

    if (isValidEmail(email)) {
      form.find('.loading').show();
      var url = WP_DATA.ajaxUrl,
          data = form.serialize();
      $.post( url, data, function(data) {
        data = $.parseJSON(data);
        console.log(data);
        if (data.is_valid === true) {
          msg = 'Thank you!';
          output.removeClass('error').addClass('success').html(msg).show();
          form.find('.email').attr("disabled","disabled");
          form.find('.submit').attr("disabled","disabled");
        } else {
          if (data.error_msg) {
            msg = data.error_msg;
          } else {
            msg = 'Please enter a valid email';
          }
          output.removeClass('success').addClass('error').html(msg).show();
        }
        form.find('.loading').hide();
      });
    } else {
      msg = 'Please enter a valid email';
      output.removeClass('success').addClass('error').html(msg).show();
    }
  }

  function isValidEmail(email) {
    var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    return re.test(email);
  }

})(jQuery); // Fully reference jQuery after this point.
